<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs defaultActiveKey="0"
              :tabBarStyle="{
        margin: '0',
      }">
        <a-tab-pane key="0"
                    tab="开工审查"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <a-dropdown>
          <div class="button">
            <img class="download"
                 :src="downloadImage"
                 alt="" />
            <span>下载模板</span>
          </div>
          <div slot="overlay"
               class="content">
            <div v-for="item in downloadFileList"
                 :key="item.id"><span @click="download(item)">《{{item.name}}》</span></div>
          </div>
        </a-dropdown>
      </template>
    </Pane>
    <div class="container">
      <a-form :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
              :colon="false">
        <a-row>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="当前项目"
                         class="default-highlight">
              <a-input :disabled="true"
                       :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="工程名称"
                         class="default-highlight"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-input :disabled="true"
                       :placeholder="projectName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <span slot="label"
                    class="">相关附件</span>
              <div class="link-list"
                   v-decorator="[
            'file',
            {
              rules: [
                {
                  required: true,
                  message: '请上传附件！',
                },
              ],
            },
          ]">
                <a-checkbox-group :value="selectedFileList"
                                  @change="onSelectFile"
                                  v-if="fileList.length !== 0">
                  <div class="link"
                       v-for="item in fileList"
                       :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank"
                         download
                         :href="item.completePath">{{
                      item.name
                    }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div style="color:#ccc;"
                     v-else-if="fileList.length === 0 && id">
                  无
                </div>
                <div style="color:#ccc;line-height:1.5;"
                     v-else>
                  《总承包项目开工条件审查表》<br>
                  《分包项目开工前置条件审查表》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>
                  <div class="control"
                       @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit"
                      type="primary"
                      v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </div>

  </div>
</template>
<script>
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";

import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  downloadTemplate,
} from "@/api/epc/construction";

import { mapGetters } from "vuex";

export default {
  name: "construCom",
  components: {
    FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_construction_start_review",
      isView: false,

      form: this.$form.createForm(this),
      pid: "",
      id: "",
      hid: "",
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
    downloadFileList() {
      //导出文件
      let list = [
        {
          value: "construction_start_review_root",
        },
        {
          value: "construction_start_review_sub",
        },
      ];

      list.forEach((item, index) => {
        item.id = index;
        item.name = this.findSingleDictName("epc_file_export_type", item.value);
      });
      return list;
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
    this.getName();
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id }).then((res) => {
        if (!res.id) return;

        if (res.attachmentList) {
          this.form.setFieldsValue({
            file: res.attachmentList,
          });
          this.fileList = res.attachmentList;
        }
      });
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err) => {
        if (!err) {
          // 如果没有id ，则新增
          if (!this.id) {
            add({
              pid: this.pid,
              designCode: this.designCode,
              projectName: this.projectName,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              id: this.id,
              pid: this.pid,
              historyId: this.hid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },

    download(item) {
      downloadTemplate(item.value).then((blob) => {
        saveAs(blob, `${item.name}模版.docx`);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  font-size: 12px;
  width: 60px;
  height: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-right: 8px;
  img {
    margin-bottom: 5px;
  }
  span {
    color: #1890ff;
  }
}
.content {
  background-color: #fff;
  border: 1px solid #ccc;
  div {
    padding: 10px 5px;
    &:hover {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
  }
  div:last-child {
    margin-bottom: 0;
  }
}

.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
